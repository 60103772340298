// This composable returns the roles of the current user in the context of it's current organization.
// Since a user can belong to multiple organizations, and switch between those, the roles are scoped
// to the current organization and should be reactive too. This composable takes care of that.
// It also provides some convenience properties to check if the user is an admin, or a volunteer, etc.
//
// NOTE: We set this up in a way that makes it usable for both Volunteerly and Stagedoos. We need to
// think about a better way to define roles for each app, e.g. i the app settings or by overwriting them
// in each app layer.
//
export default function useCurrentOrganizationRoles() {
  const { roles } = storeToRefs(useCurrentUserStore())
  const { currentOrganization } = storeToRefs(useCurrentOrganizationStore())

  const currentOrganizationRoles = computed(() => {
    return roles.value.filter(
      role =>
        role.resourceId === currentOrganization.value?.id
        && role.resourceType == 'AuthEngine::Organization',
    )
  })

  // const hasOrganizationRole = computed(() => !!currentOrganizationRoles.value)
  const isAdmin = computed(() =>
    currentOrganizationRoles.value.some(
      role => role.name === OrganizationRole.Admin,
    ),
  )
  // A subset of isAdmin
  const isNonProfitAdmin = computed(
    () => isAdmin.value && currentOrganization.value?.type === 'NonProfit',
  )
  // A subset of isAdmin
  const isCenterAdmin = computed(
    () =>
      isAdmin.value && currentOrganization.value?.type === 'VolunteerCenter',
  )
  // A subset of isAdmin
  const isSchoolAdmin = computed(
    () => isAdmin.value && currentOrganization.value?.type === 'School',
  )
  // Either a school or center admin - for convenience usage across Stagedoos and Volunteerly
  const isSchoolOrCenterAdmin = computed(
    () => isCenterAdmin.value || isSchoolAdmin.value,
  )

  // We purposely don't check for a volunteer role, because it can happen that a user has multiple roles
  // on the same organization, at this moment, and we want the roles to be (sort of) exclusive in the frontend.
  const isVolunteer = computed(() => !isAdmin.value && !isCenterAdmin.value)

  return {
    currentOrganizationRoles,
    isAdmin,
    isNonProfitAdmin,
    isCenterAdmin,
    isSchoolAdmin,
    isSchoolOrCenterAdmin,
    isVolunteer,
    // Convenience proxy of the currentUser store
    roles,
  }
}
